<template>
    <div>
        <div class="header">
            <div class="title">All Shanties</div>
        </div>
        <div class="wrap-all">
                <div v-for="shanty in shanties" v-bind:key="shanty.file">
                    <router-link :to="'/shanty/'+shanty.file" class="shanty-link">
                        <div>{{shanty.name}}</div>
                        <Right/>
                    </router-link>
                </div>
        </div>
    </div>    
</template>

<script>
import ShantyList from '@/assets/shanties/available-shanties.json';
import Right from 'vue-material-design-icons/ChevronRight.vue';
export default {
    components: {
        Right
    },
    computed: {
        shanties: function() {
            return ShantyList.shanties.sort( (a,b) => {
                return a.name > b.name;
            });
        }
        
    }
}
</script>

<style lang="scss" scoped>
    .header {
        padding: 1.5rem;
        .title {
            font-size: 1.5rem;
            font-weight: 300;
            color: #00f6ff;
        }
    }
    .shanty-link {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #13002d;
        color: inherit;
        text-decoration: none;
        border-bottom: 1px solid #00f6ff;
    }
    .wrap-all {
        border-top: 1px solid #00f6ff;
    }
    .shanty-link-wrap:hover {
        background: #370087;
        border-bottom: 1px solid #00f6ff;
    }
</style>